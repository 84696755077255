import React, { useContext, useState } from "react";
import { useFormik } from "formik";

//Style
import Styles from "./BidForm.module.scss";

//Components
import Flex from "@vahak/core-ui/dist/layout/Flex";
import LoadDetailsSection from "./load-details-section/LoadDetailsSection";
import { BID_ACTIONS, BiddingFormContext } from "../../../../BiddingFormContextProvider";
import BiddingSection from "./bidding-section/BiddingSection";
import { ModalCrossIcon } from "@vahak/core-ui/dist/components/Modal/Modal";
import { LoadMarketCard } from "../../../../services/load/useGetLoadMarketV2/type";
import { BidFormValidationSchema } from "./validation-schema/ValidationSchema";
import Button from "@vahak/core-ui/dist/components/Button";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";
import UserNameWithBadge from "../../../user-name-with-badge/UserNameWithBadge";
import { BidType, usePostLoadBid } from "../../../../services/bid/usePostLoadBid";
import { AppContext } from "@vahak/core/dist/app-context";
import { toast } from "@vahak/core/dist/components/toast/toast";
import useValidateUserStatus from "../../../../hooks/useValidateUserStatus";
import { BiddingFormValues } from "../common/type";
import { AssuredLoadEvents, GA4EventNames, VWOEvents } from "@vahak/core/dist/constants/event-names";

import { IDs } from "@vahak/core/dist/constants/automation";

import { useEventTrackerService } from "@vahak/core/dist/_services/hooks/useEventTrackerService";

import { lorryDetailsAlreadySelected } from "../LoadBidFormNew";
import AssuredPaymentConfirmation from "./assured-load/AssuredPaymentConfirmation";
import VahakAssuredIcon from "@vahak/core/dist/icons/vahakAssured.svg";
import { LoadBookingInfo } from "@vahak/core/dist/custom-types/common-preloads";
import { useRouter } from "next/router";
import { usePayment } from "../../../../context/payment-context";
import { BID_UPDATE_TYPE, UserScreenId } from "@vahak/core/dist/constants";
import { BookingForMap } from "../../../../services/vas/useGeneratePayment";
import { useUpdateBidService } from "@vahak/core/dist/_services/hooks/useTransactionService";
import { useQueryClient } from "react-query";
import queryNames from "../../../../services/queryNames";
import { IEventTrackerProp } from "../../../../types/events";

interface BidFormProps extends IEventTrackerProp {
    lorryAlreadySelected?: lorryDetailsAlreadySelected;
    onSuccessLoadBid?: () => void;
}

export interface IBidContextPayload {
    data: LoadMarketCard;
    paymentInfo?: LoadBookingInfo;
    bidId?: number;
    bidSubmitTime?: number;
}

const BidForm = ({ lorryAlreadySelected, onSuccessLoadBid, trackEvent }: BidFormProps) => {
    const { bidFormConfig, dispatchBid } = useContext(BiddingFormContext);
    const {
        companyId,
        customerCompanyId,
        setMandateUserVerificationActionType,
        setMandateUserVerificationActionTriggered,
        setMandateUserVerificationCallBackFunction,
        isCsDashboardUser
    } = useContext(AppContext);

    const queryClient = useQueryClient();
    const router = useRouter();
    const { updatePaymentData } = usePayment();

    const { sendGAandMoEngageEvent } = useEventTrackerService();
    const { mutateAsync: mutateUpdateBid } = useUpdateBidService("load");

    const loadData = bidFormConfig.load.data as LoadMarketCard;

    const isTapNGoAssuredLoad = loadData?.load_detail?.is_tap_n_go;

    /**
     * Load bidding verification check
     */
    const { ValidateLoadBidding } = useValidateUserStatus();

    const { mutateAsync: postBid } = usePostLoadBid();

    const resetBidForm = () => {
        dispatchBid({
            type: BID_ACTIONS.LOAD,
            payload: { id: 0, open: false }
        });
        const loadLorryId = router.query["loadbid"] || router.query["lorrybid"];
        if (loadLorryId) {
            delete router.query["lorrybid"];
            delete router.query["loadbid"];
            delete router.query["oppcmpid"];
            delete router.query["utm_medium"];
            delete router.query["utm_source"];
            delete router.query["_branch_match_id"];
            delete router.query["_branch_referrer"];
            delete router.query["r"];
            delete router.query["u_i"];
            delete router.query["utm_campaign"];
            router.replace(router, { query: "" }, { shallow: true, scroll: false });
        }
    };

    const openPreferredAmountForm = () => {
        !isCsDashboardUser &&
            finalCompanyId &&
            dispatchBid({
                type: BID_ACTIONS.LOAD_PREFERRED_AMT,
                payload: {
                    open: true,
                    id: loadData.load_detail?.id,
                    price: loadData.load_detail?.expected_amount
                }
            });
    };

    const finalCompanyId = Number(customerCompanyId || companyId);

    const submitBid = async (formData: BiddingFormValues, ci: any) => {
        postBid(
            {
                amount: Number(formData.expectedAmount),
                bidder_comment: "",
                bidder_id: finalCompanyId,
                load_id: loadData.load_detail.id,
                quantity: loadData.load_detail.quantity,
                vehicle_number: formData.lorryNum,
                lorry_id: formData.lorryId
            },
            {
                onSuccess: (data) => {
                    /**
                     * Events
                     */
                    sendGAandMoEngageEvent({ name: "Marketplace_bid_now_web", data: { value: 1 } }, true, false);
                    trackEvent?.(GA4EventNames.MARKET_PLACE["bid_submitted"]);
                    trackEvent?.(GA4EventNames.MARKET_PLACE["load_bid_submitted"]);
                    window.VWO?.event(VWOEvents.bid_success, {
                        type: "load"
                    });

                    if (isTapNGoAssuredLoad) {
                        const bookingData = {
                            amount: data?.data?.tap_n_go_info?.lorry_owner_booking_amount,
                            bid_id: data?.data?.id,
                            bid_type: BidType.LOAD_BID,
                            booking_for: BookingForMap.TAP_N_GO,
                            load_id: loadData?.load_detail?.id
                        };

                        const path = router.asPath;
                        const handleBack = () => {
                            router.replace(path, undefined, { shallow: true });
                        };

                        const cancelBid = async () => {
                            !!data?.data?.id &&
                                (await mutateUpdateBid({
                                    lastBidId: data?.data?.id,
                                    requestType: BID_UPDATE_TYPE.EXPIRE
                                }));
                            handleBack();
                        };

                        updatePaymentData?.({
                            type: "assured-booking-amount",
                            srcUrl: path,
                            allowCoinUse: true,
                            bidBookingInfo: bookingData,
                            onClickBack: cancelBid,
                            onSuccess: () => {
                                trackEvent?.(AssuredLoadEvents["payment_success"]);
                            },
                            postSuccess:
                                bidFormConfig.load?.onSuccess || onSuccessLoadBid
                                    ? () => {
                                          bidFormConfig.load?.onSuccess?.();
                                          onSuccessLoadBid?.();
                                      }
                                    : handleBack
                        });

                        setTimeout(() => {
                            router?.push("/" + UserScreenId["payment"], undefined, {
                                shallow: true
                            });
                        }, 0);
                    } else {
                        toast.success(
                            <>
                                Your bid has been posted successfully! <br /> Contact details will be sent via
                                SMS/WhatsApp once the bid has been accepted.
                            </>,
                            { autoClose: 4000 }
                        );
                        setTimeout(() => {
                            queryClient.refetchQueries([queryNames.getLoadMarketInfiniteQueryV2], {
                                inactive: true
                            });
                        }, 4000);
                        onSuccessLoadBid?.();
                        bidFormConfig.load?.onSuccess?.();
                    }
                    resetBidForm();
                },
                onError: (err) => {
                    toast.error(err?.message);
                    dispatchBid({ type: BID_ACTIONS.LOAD, payload: { id: 0, open: false } });
                }
            }
        );
    };

    const formik = useFormik({
        validateOnBlur: true,
        validateOnChange: true,
        isInitialValid: loadData.load_detail.expected_amount ? true : false,
        initialValues: {
            expectedAmount: loadData.load_detail.expected_amount,
            lorryNum: "",
            isFixedPrice: true,
            ...lorryAlreadySelected
        },
        validationSchema: BidFormValidationSchema,
        onSubmit: async (formData) => {
            await submitBid(formData, companyId);
        }
    });

    const isMobileScreen = useMediaQuery({ queryType: "mobile" });

    return (
        <div className={Styles.bidFormWrapper}>
            {/* This will be shown only in mobile bottom sheet */}
            {isMobileScreen ? (
                <Flex justifyContent="space-between" className={Styles.sheetHeader}>
                    <UserNameWithBadge
                        companyLogo={loadData.company_detail.logo}
                        name={loadData.company_detail.name}
                        isBankVerified={loadData.company_detail.is_bank_verified}
                        isVerified={loadData.company_detail.is_company_verified}
                        isAadhaarVerified={loadData.company_detail.is_aadhaar_verified}
                        isGstVerified={loadData.company_detail.is_gst_verified}
                        isPanVerified={loadData.company_detail.is_company_verified}
                        isMember={loadData.company_detail.is_member}
                        rating={loadData.company_detail.ratings}
                        companyNameId={IDs.bidForm.bidForm_companyName}
                    />
                    <div
                        id={IDs.bidForm.bidForm_btn_close}
                        onClick={() => {
                            setMandateUserVerificationActionType?.("");
                            setMandateUserVerificationActionTriggered?.(false);
                            setMandateUserVerificationCallBackFunction?.(undefined);
                            dispatchBid({ type: BID_ACTIONS.LOAD, payload: { id: 0, open: false } });
                            isTapNGoAssuredLoad && openPreferredAmountForm();
                        }}
                    >
                        <ModalCrossIcon />
                    </div>
                </Flex>
            ) : (
                <></>
            )}

            <Flex className={Styles.bidFormFlexContainer}>
                <Flex className={Styles.loadDetailsSection}>
                    <LoadDetailsSection loadData={bidFormConfig.load.data} />
                </Flex>
                <Flex className={Styles.bidForm} flexDirection="column" alignItems="center" gap={30}>
                    {isTapNGoAssuredLoad ? (
                        <div className={isMobileScreen ? Styles.loadDetailsSectionWithAssuredHighlightForMobile : ""}>
                            {isMobileScreen && (
                                <div className={Styles.assuredLoadIcon}>
                                    <VahakAssuredIcon />
                                </div>
                            )}
                            <AssuredPaymentConfirmation
                                disableBtn={formik.isSubmitting || isCsDashboardUser}
                                closeBidForm={() => {
                                    resetBidForm();
                                    openPreferredAmountForm();
                                }}
                                bidPrice={loadData?.load_detail?.amount}
                                onClickBid={() =>
                                    Boolean(companyId) ? formik.submitForm() : ValidateLoadBidding(formik.submitForm)
                                }
                                additionalCharges={loadData?.load_detail?.meta_data?.loading_charges_detail?.charges}
                                companyId={finalCompanyId}
                                trackEvent={trackEvent}
                            />
                        </div>
                    ) : (
                        <>
                            <div className={Styles.formFieldsWrapper}>
                                <BiddingSection
                                    loadData={bidFormConfig.load.data}
                                    formikConfig={formik}
                                    trackEvent={trackEvent}
                                />
                            </div>

                            <Button
                                blockBtn
                                disabled={formik.isSubmitting || !formik.isValid}
                                type="submit"
                                onClick={() =>
                                    Boolean(companyId) ? formik.submitForm() : ValidateLoadBidding(formik.submitForm)
                                }
                                id={IDs.bidForm.bidFormBidNowButton}
                            >
                                Bid now
                            </Button>
                        </>
                    )}
                </Flex>

                <div
                    id={IDs.bidForm.bidForm_btn_close}
                    className={Styles.bidModalClose}
                    onClick={() => {
                        setMandateUserVerificationActionType?.("");
                        setMandateUserVerificationActionTriggered?.(false);
                        setMandateUserVerificationCallBackFunction?.(undefined);
                        dispatchBid({ type: BID_ACTIONS.LOAD, payload: { id: 0, open: false } });
                        isTapNGoAssuredLoad && openPreferredAmountForm();
                    }}
                >
                    <ModalCrossIcon />
                </div>
            </Flex>
        </div>
    );
};

export default BidForm;
